import React, { FC, useState, createContext } from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import classNames from "classnames";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Search from "../../components/Search/Search";
import { Link } from "@qwilr/kaleidoscope";
import "../../index.less";
import { lightTheme } from "@qwilr/kaleidoscope/tokens";

export interface IMainContext {
  category: string;
  pathname: string;
  sidebarOpen: boolean;
  setSidebarOpen: (isOpen: boolean) => void;
  hasSidebar: boolean;
  setHasSidebar: (hasSidebar: boolean) => void;
}

export const MainContext = createContext({} as IMainContext);

const MainLayout: FC<PageProps> = ({ children, location }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hasSidebar, setHasSidebar] = useState(false);
  const { pathname } = location;

  const data = useStaticQuery(graphql`
    query MainLayoutQuery {
      allNavbarYaml {
        nodes {
          label
        }
      }
    }
  `);

  const category = pathname.split("/").filter(Boolean)[0];
  const showSidebar = data.allNavbarYaml.nodes
    .map(({ label }: { label: string }) => label.toLowerCase())
    .includes(category);
  const mainId = "kld-main-content";

  return (
    <MainContext.Provider value={{ category, pathname, sidebarOpen, setSidebarOpen, hasSidebar, setHasSidebar }}>
      <div className={["kld-main-layout", lightTheme].join(" ")}>
        <Link className="kld-main-layout__skip-link" href={`#${mainId}`}>
          Skip to main content
        </Link>
        <Navbar toggleSearch={() => setSearchOpen(!searchOpen)} />
        <Search open={searchOpen} setOpen={setSearchOpen} />
        {showSidebar && <Sidebar />}
        <main
          id={mainId}
          tabIndex={-1}
          className={classNames("kld-main-layout__content", { "kld-main-layout__content--full": !showSidebar })}
        >
          {children}
        </main>
      </div>
    </MainContext.Provider>
  );
};

export default MainLayout;
